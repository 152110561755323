import { ActionContext } from 'vuex'
import constants from './-constants'

const { ADD_SPACE, REMOVE_SPACE, CLEAR_STATE, CHANGE_CART_SPACES_STATE, CLEAR_ALL } = constants.action
const { SET_SPACES, CLEAR_STATE: MUTATION_CLEAR_STATE, SET_CART_SPACES_STATE } = constants.mutation

export default {
  [ADD_SPACE]({ commit, state, rootState }: ActionContext<any, any>, payload: any) {
    const currentSpaces = [...state.spaces]
    const duplicateSpace = currentSpaces.find((s) => s.id === payload.id)
    if (duplicateSpace) return
    currentSpaces.push(payload)
    commit(SET_SPACES, currentSpaces)

    // Add to cache for current active project
    try {
      if (typeof localStorage !== 'undefined') {
        const cache: any = JSON.parse(localStorage.getItem(constants.cacheName) as string) || {}
        const activeProject = rootState.project.activeProject
        if (!(activeProject in cache)) cache[activeProject] = []
        cache[activeProject].push(payload)
        localStorage.setItem(constants.cacheName, JSON.stringify(cache))
      }
    } catch (e) {
      // console.log('1Unable to save Request Offer Cache Object', e)
    }
  },
  [REMOVE_SPACE]({ commit, state, rootState }: ActionContext<any, any>, payload: any) {
    const currentSpaces = [...state.spaces]
    let foundIndex = -1
    currentSpaces.find((s, i) => {
      if (s.id !== payload.id) {
        return false
      }
      foundIndex = i
      return true
    })
    if (foundIndex !== -1) {
      currentSpaces.splice(foundIndex, 1)
      commit(SET_SPACES, currentSpaces)
    }

    // Remove from cache for current active project
    try {
      if (typeof localStorage !== 'undefined') {
        const cache: any = JSON.parse(localStorage.getItem(constants.cacheName) as string) || {}
        const activeProject = rootState.project.activeProject
        if (!(activeProject in cache)) cache[activeProject] = []
        let cacheIndex = -1
        cache[activeProject].find((s: any, i: number) => {
          if (s.id !== payload.id) {
            return false
          }
          cacheIndex = i
          return true
        })
        if (cacheIndex !== -1) {
          cache[activeProject].splice(cacheIndex, 1)
          localStorage.setItem(constants.cacheName, JSON.stringify(cache))
        }
      }
    } catch (e) {
      // console.log('2Unable to save Request Offer Cache Object', e)
    }
  },
  [CLEAR_STATE]({ commit, rootState }: ActionContext<any, any>) {
    commit(MUTATION_CLEAR_STATE)
    try {
      if (typeof localStorage !== 'undefined') {
        const cache: any = JSON.parse(localStorage.getItem(constants.cacheName) as string) || {}
        // TODO: check if functionality for project page is still working
        const activeProject =
          (this.$router as any).currentRoute.params.project !== rootState.project.activeProject
            ? (this.$router as any).currentRoute.params.projectId
            : rootState.project.activeProject
        if (!(activeProject in cache)) cache[activeProject] = []
        commit(SET_SPACES, cache[activeProject])
      }
    } catch (e) {
      // console.log('3Unable to load Request Offer Cache Object', e)
    }
  },
  [CLEAR_ALL]({ commit, rootState }: ActionContext<any, any>) {
    commit(MUTATION_CLEAR_STATE)
    commit(SET_SPACES, []);
    try {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(constants.cacheName)
      }
    } catch (e) {
      // console.log('3Unable to load Request Offer Cache Object', e)
    }
  },
  [CHANGE_CART_SPACES_STATE]({ commit, rootState }: ActionContext<any, any>, payload: boolean) {
    commit(SET_CART_SPACES_STATE, payload)
  }
}
